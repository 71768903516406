'use strict';

angular.module('auth', [])

  .factory('AuthTokenFactory', function AuthTokenFactory($window) {
    var store = $window.localStorage;
    var key = 'jwt';

    return {
      getToken: getToken,
      setToken: setToken,
      hasToken: hasToken
    };

    function getToken() {
      return store.getItem(key);
    }

    function setToken(token, role) {
      if (token) {
        store.setItem(key, token);
        store.setItem('role', role);
      } else {
        store.removeItem(key);
        store.removeItem('role');
      }
    }

    function hasToken() {
      return getToken() !== null;
    }
  })

  .factory('AuthUserFactory', function AuthUserFactory($rootScope, $window) {
    var store = $window.localStorage;
    var key = 'currentUser';

    return {
      getUser: getUser,
      setUser: setUser
    };

    function getUser() {
      var user = JSON.parse(store.getItem(key));
      return user ? user : {username: null};
    }

    function setUser(user) {
      if (user) {
        store.setItem(key, JSON.stringify(user));
        $rootScope.currentUser = user;
      } else {
        store.removeItem(key);
        $rootScope.currentUser = {username: null};
      }
    }
  })

  .factory('AuthInterceptor', function AuthInterceptor($q, $injector, API_URL, AuthTokenFactory) {

    var showLoginTimeout;

    return {
      request: request,
      responseError: checkAuth
    };

    // Method to run for every request
    function request(config) {

      // Send token for API calls
      if (config.url.substring(0, API_URL.length) == API_URL) {
        addToken(config);
      }
      return config;
    }

    // Add Authorization header
    function addToken(config) {
      var token = AuthTokenFactory.getToken();
      if (token) {
        config.headers = config.headers || {};
        config.headers.Authorization = 'Bearer ' + token;
      }
      return config;
    }
    // Detect and react to 401
    function checkAuth(rejection) {

      console.log('checking auth', rejection);

      if (rejection.status == 401) {
        if (angular.isArray(rejection.data.errors) && rejection.data.errors[0].code == 'errors.login-required') {
          console.log("Login required");
          showLogin();
        }
      }
      return $q.reject(rejection);
    }

    function showLogin() { // todo replace with $timeout?
      clearTimeout(showLoginTimeout);
      showLoginTimeout = setTimeout(function() {
        alert("You must be logged in to do this");
      }, 250);
    }
  })
;
